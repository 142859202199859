import React from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import { validateForm } from "utils/validation";
import moment from "moment";
import { FaEye } from "react-icons/fa";

import Errors from "Notifications/Errors";
import { openBase64File } from "utils/downloadHelper";

const EditProviderOigExclusionModal = ({
  provider_id,
  modal,
  setModal,
  isDisabled,
  setDisabled,
  formData,
  setFormData,
  errorList,
  setErrors,
  currentProviderOigExclusion,
  loadProviderOigExclusionFormData,
  getProviderOigExclusionById,
  saveProviderOigExclusion,
  removeProviderOigExclusionErrors,
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [submittingPsv, setSubmittingPsv] = React.useState(false);
  const [isAdminVerified, setIsAdminVerified] = React.useState(false);

  const {
    OIG_Exclusion,
    OIG_ExclusionDate,
    OIG_ExclusionType,
    OIG_ExclusionWaiver,
    OIG_Verified,
    OIG_DateVerified,
  } = formData;

  const saveFormData = (formData) => {
    // Save formData
    saveProviderOigExclusion(formData, provider_id).then((res) => {
      if (!res.status) return;
      reset();
      getProviderOigExclusionById(provider_id);
    });
  };

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    const isVerified = e.target.checked;

    switch (e.target.name) {
      case "OIG_Exclusion":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          OIG_ExclusionDate: isVerified ? OIG_ExclusionDate : "",
          OIG_ExclusionType: isVerified ? OIG_ExclusionType : "",
          OIG_ExclusionWaiver: isVerified ? OIG_ExclusionWaiver : "",
          OIG_Verified: isVerified ? OIG_Verified : false,
          OIG_DateVerified: isVerified ? OIG_DateVerified : "",
        });
        break;

      case "OIG_Verified":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          OIG_DateVerified: isVerified ? OIG_DateVerified : "",
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderOigExclusionErrors();

    const validationRules = [];

    const addValidationRule = (param, msg) => {
      validationRules.push({ param, msg });
    };

    if (OIG_Exclusion) {
      if (!OIG_ExclusionDate)
        addValidationRule(
          "OIG_ExclusionDate",
          "The OIG Exclusion Date is required"
        );
      if (!OIG_ExclusionType)
        addValidationRule(
          "OIG_ExclusionType",
          "The OIG Exclusion Type is required"
        );
      if (!OIG_ExclusionWaiver)
        addValidationRule(
          "OIG_ExclusionWaiver",
          "The OIG Exclusion Waiver is required"
        );
      if (OIG_Verified)
        addValidationRule(
          "OIG_DateVerified",
          "The OIG Verified Date is required"
        );
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
    setSubmitting(true);

    saveProviderOigExclusion(submitData, provider_id).then((res) => {
      if (!res.status) return;
      reset();
      getProviderOigExclusionById(provider_id);
    });
  };

  const reset = () => {
    setModal(false);
    setDisabled(!isDisabled);
    setSubmitting(false);
    setSubmittingPsv(false);
    removeProviderOigExclusionErrors();
    if (!currentProviderOigExclusion) return;
    loadProviderOigExclusionFormData(currentProviderOigExclusion);
  };

  const initialPSV_Data = {
    OIG_Exclusion_psv: "",
    OIG_ExclusionDate_psv: "",
    OIG_ExclusionType_psv: "",
    OIG_ExclusionWaiver_psv: "",
    psv_report: null,
  };

  const [PSV_Data, setPSV_Data] = React.useState(initialPSV_Data);
  const {
    OIG_Exclusion_psv,
    OIG_ExclusionDate_psv,
    OIG_ExclusionType_psv,
    OIG_ExclusionWaiver_psv,
    psv_report,
  } = PSV_Data;

  React.useEffect(() => {
    if (!currentProviderOigExclusion) return;

    const admin_verify = currentProviderOigExclusion.admin_verify
      ? currentProviderOigExclusion.admin_verify
      : false;
    setIsAdminVerified(admin_verify);

    const { psv_result = {} } = currentProviderOigExclusion;
    if (!psv_result["full_result"]) {
      setPSV_Data(initialPSV_Data);
      return;
    }

    const psv_full_result = psv_result["full_result"]
      ? psv_result["full_result"]
      : {};
    const psv_report = psv_result["reports"] ? psv_result["reports"][0] : null;

    let exclusion_psv = "";
    if (
      psv_full_result["OIG_Exclusion"] &&
      psv_full_result["OIG_Exclusion"].toLowerCase() === "yes"
    ) {
      exclusion_psv = "Yes";
    } else if (
      psv_full_result["OIG_Exclusion"] &&
      psv_full_result["OIG_Exclusion"].toLowerCase() == "no"
    ) {
      exclusion_psv = "No";
    }

    const exclusionDate_psv = psv_full_result["OIG_ExclusionDate"]
      ? psv_full_result["OIG_ExclusionDate"]
      : "";

    const exclusionType_psv = psv_full_result["OIG_ExclusionType"]
      ? psv_full_result["OIG_ExclusionType"]
      : "";

    const exclusionWaiver_psv = psv_full_result["OIG_Waiver"]
      ? psv_full_result["OIG_Waiver"]
      : "";

    const PSV_Data = {
      OIG_Exclusion_psv: exclusion_psv,
      OIG_ExclusionDate_psv: exclusionDate_psv
        ? moment(exclusionDate_psv).format("MM/DD/YYYY").toString()
        : "",
      OIG_ExclusionType_psv: exclusionType_psv,
      OIG_ExclusionWaiver_psv: exclusionWaiver_psv,
    };

    if (psv_report) {
      PSV_Data["psv_report"] = psv_report;
    }

    setPSV_Data((prev) => ({ ...prev, ...PSV_Data }));
  }, [currentProviderOigExclusion]);

  const onDownloadClickHandler = () => {
    if (!psv_report) return;

    const base64 = psv_report["base64"];
    const file_title = psv_report["title"] ? psv_report["title"] : "Report.pdf";

    openBase64File(base64, file_title, "application/pdf");
  };

  const onAcceptPsvResults = (e) => {
    e.preventDefault();

    formData.OIG_Exclusion = OIG_Exclusion_psv === "Yes" ? true : false;
    formData.OIG_ExclusionDate = moment(OIG_ExclusionDate_psv).format(
      "YYYY-MM-DD"
    );
    formData.OIG_ExclusionType = OIG_ExclusionType_psv
      ? OIG_ExclusionType_psv
      : "";
    formData.OIG_ExclusionWaiver = OIG_ExclusionWaiver_psv
      ? OIG_ExclusionWaiver_psv
      : "";
    formData.OIG_Verified = true;
    formData.OIG_DateVerified = moment().format("YYYY-MM-DD");
    formData.admin_verify = true;

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );

    setSubmittingPsv(true);
    saveFormData(submitData);
  };

  return (
    <Modal show={modal} onHide={reset} size="lg">
      <Modal.Header closeButton>
        <h4>OIG Exclusion?</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="OIG_Exclusion">OIG Exclusion?</Form.Label>
                <Form.Check
                  className={errorList.OIG_Exclusion ? "invalid" : ""}
                  type="switch"
                  id="OIG_Exclusion"
                  name="OIG_Exclusion"
                  checked={OIG_Exclusion}
                  onChange={(e) => onChange(e)}
                  label={OIG_Exclusion === true ? "Yes" : "No"}
                  disabled={isDisabled}
                />
                <Errors current_key="OIG_Exclusion" key="OIG_Exclusion" />
              </Form.Group>
            </Col>

            {OIG_Exclusion_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OIG_Exclusion_psv">
                    OIG Exclusion PSV
                  </Form.Label>
                  <Form.Control
                    type="input"
                    id="OIG_Exclusion_psv"
                    defaultValue={OIG_Exclusion_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="OIG_ExclusionDate">
                  OIG Exclusion Date
                </Form.Label>
                <Form.Control
                  className={errorList.OIG_ExclusionDate ? "invalid" : ""}
                  type="date"
                  id="OIG_ExclusionDate"
                  name="OIG_ExclusionDate"
                  value={OIG_ExclusionDate}
                  onChange={(e) => onChange(e)}
                  disabled={isDisabled || !OIG_Exclusion}
                />
                <Errors
                  current_key="OIG_ExclusionDate"
                  key="OIG_ExclusionDate"
                />
              </Form.Group>
            </Col>

            {OIG_ExclusionDate_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OIG_ExclusionDate_psv">
                    OIG Exclusion Date PSV (mm/dd/yyyy)
                  </Form.Label>
                  <Form.Control
                    type="input"
                    id="OIG_ExclusionDate_psv"
                    defaultValue={OIG_ExclusionDate_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="OIG_ExclusionType">
                  OIG Exclusion Type
                </Form.Label>
                <Form.Control
                  className={errorList.OIG_ExclusionType ? "invalid" : ""}
                  type="text"
                  id="OIG_ExclusionType"
                  name="OIG_ExclusionType"
                  value={OIG_ExclusionType}
                  onChange={(e) => onChange(e)}
                  disabled={isDisabled || !OIG_Exclusion}
                />
                <Errors
                  current_key="OIG_ExclusionType"
                  key="OIG_ExclusionType"
                />
              </Form.Group>
            </Col>

            {OIG_ExclusionType_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OIG_ExclusionType_psv">
                    OIG Exclusion Type PSV
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="OIG_ExclusionType_psv"
                    defaultValue={OIG_ExclusionType_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="OIG_ExclusionWaiver">
                  OIG Waiver
                </Form.Label>
                <Form.Control
                  className={errorList.OIG_ExclusionWaiver ? "invalid" : ""}
                  type="text"
                  id="OIG_ExclusionWaiver"
                  name="OIG_ExclusionWaiver"
                  value={OIG_ExclusionWaiver}
                  onChange={(e) => onChange(e)}
                  disabled={isDisabled || !OIG_Exclusion}
                />
                <Errors
                  current_key="OIG_ExclusionWaiver"
                  key="OIG_ExclusionWaiver"
                />
              </Form.Group>
            </Col>

            {OIG_ExclusionWaiver_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OIG_ExclusionWaiver_psv">
                    OIG Waiver PSV
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="OIG_ExclusionWaiver_psv"
                    defaultValue={OIG_ExclusionWaiver_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="OIG_Verified">Verified</Form.Label>
                <Form.Check
                  className={errorList.OIG_Verified ? "invalid" : ""}
                  type="switch"
                  id="OIG_Verified"
                  name="OIG_Verified"
                  checked={OIG_Verified}
                  onChange={(e) => onChange(e)}
                  label={OIG_Verified === true ? "Yes" : "No"}
                  disabled={isDisabled || !OIG_Exclusion}
                />
                <Errors current_key="OIG_Verified" key="OIG_Verified" />
              </Form.Group>
            </Col>

            {psv_report ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="psv_report">PSV Report</Form.Label>

                  <div>
                    <Button
                      type="button"
                      variant="link"
                      size="sm"
                      onClick={() => onDownloadClickHandler()}
                    >
                      <FaEye /> View
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="OIG_DateVerified">
                  Date Verified
                </Form.Label>
                <Form.Control
                  className={errorList.OIG_DateVerified ? "invalid" : ""}
                  type="date"
                  id="OIG_DateVerified"
                  name="OIG_DateVerified"
                  value={OIG_DateVerified}
                  onChange={(e) => onChange(e)}
                  disabled={isDisabled || !OIG_Verified || !OIG_Exclusion}
                />
                <Errors current_key="OIG_DateVerified" key="OIG_DateVerified" />
              </Form.Group>
            </Col>
          </Row>

          {!isDisabled ? (
            <div className="float-end">
              {OIG_ExclusionDate_psv && !isAdminVerified ? (
                <Button
                  className="m-2"
                  type="button"
                  variant="primary"
                  disabled={submitting || submittingPsv}
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to accept Psv Results?`
                      )
                    ) {
                      onAcceptPsvResults(e);
                    }
                  }}
                >
                  {submittingPsv ? (
                    <>
                      <span className="spinner-border spinner-border-sm"></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Accept PSV Results</>
                  )}
                </Button>
              ) : null}
              <Button
                className="m-2"
                type="button"
                variant="primary"
                disabled={submitting || submittingPsv}
                onClick={(e) => onSubmit(e)}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting || submittingPsv}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProviderOigExclusionModal;
