import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isAdmin } from "utils/helper";
import { MdEdit } from "react-icons/md";
import Spinner from "views/Spinner";

import {
  setErrors,
  removeProviderOigExclusionErrors,
  getProviderOigExclusionById,
  saveProviderOigExclusion,
  resetProviderOigExclusionComponentStore,
} from "actions/providerOigExclusionActions";
import EditProviderOigExclusionModal from "./EditProviderOigExclusionModal";
import NoData from "./NoData";

const ProviderOigExclusion = ({
  user,
  setErrors,
  errorList,
  removeProviderOigExclusionErrors,
  getProviderOigExclusionById,
  saveProviderOigExclusion,
  currentProviderOigExclusion,
  loadingProviderOigExclusion,
  resetProviderOigExclusionComponentStore,
}) => {
  const { provider_id } = useParams();
  const [modal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);

  const initialFormData = {
    OIG_Exclusion: false,
    OIG_ExclusionDate: "",
    OIG_ExclusionType: "",
    OIG_ExclusionWaiver: "",
    OIG_Verified: false,
    OIG_DateVerified: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);

  const {
    OIG_Exclusion,
    OIG_ExclusionDate,
    OIG_ExclusionType,
    OIG_ExclusionWaiver,
    OIG_Verified,
    OIG_DateVerified,
  } = formData;

  const loadProviderOigExclusionFormData = (currentProviderOigExclusion) => {
    const {
      OIG_Exclusion,
      OIG_ExclusionDate,
      OIG_ExclusionType,
      OIG_ExclusionWaiver,
      OIG_Verified,
      OIG_DateVerified,
    } = currentProviderOigExclusion;

    const data = {
      OIG_Exclusion,
      OIG_ExclusionDate: OIG_ExclusionDate
        ? moment(OIG_ExclusionDate).format("YYYY-MM-DD")
        : "",
      OIG_ExclusionType,
      OIG_ExclusionWaiver,
      OIG_Verified,
      OIG_DateVerified: OIG_DateVerified
        ? moment(OIG_DateVerified).format("YYYY-MM-DD")
        : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!provider_id) return;
    resetProviderOigExclusionComponentStore();
    getProviderOigExclusionById(provider_id);
  }, [provider_id, getProviderOigExclusionById]);

  React.useEffect(() => {
    if (!currentProviderOigExclusion) {
      setFormData(initialFormData);
      return;
    }
    loadProviderOigExclusionFormData(currentProviderOigExclusion);
  }, [currentProviderOigExclusion]);

  const onEditClickHandler = () => {
    setModal(true);
    setDisabled(!isDisabled);
  };

  return (
    <React.Fragment>
      <EditProviderOigExclusionModal
        provider_id={provider_id}
        modal={modal}
        setModal={setModal}
        isDisabled={isDisabled}
        setDisabled={setDisabled}
        formData={formData}
        setFormData={setFormData}
        errorList={errorList}
        setErrors={setErrors}
        currentProviderOigExclusion={currentProviderOigExclusion}
        loadProviderOigExclusionFormData={loadProviderOigExclusionFormData}
        getProviderOigExclusionById={getProviderOigExclusionById}
        saveProviderOigExclusion={saveProviderOigExclusion}
        removeProviderOigExclusionErrors={removeProviderOigExclusionErrors}
      />
      <Col xs="12" sm="6" lg="6">
        <Card className="mt-5">
          <Card.Body className="misc-info-card">
            {" "}
            <div className="card-heading mb-3">
              {" "}
              <h4 className="header-title">OIG Exclusion</h4>
              {isAdmin(user) ? (
                <Button
                  variant="link"
                  size="sm"
                  className="float-end"
                  onClick={() => onEditClickHandler()}
                >
                  <MdEdit title="Click to Edit" size={20} />
                </Button>
              ) : null}
            </div>
            {loadingProviderOigExclusion ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {OIG_Exclusion ? (
                  <Row>
                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>OIG Exclusion?</div>
                      <div className="fw-bold">
                        {OIG_Exclusion ? "Yes" : "No"}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>OIG Exclusion Date</div>
                      <div className="fw-bold">
                        {moment(OIG_ExclusionDate).format("MM/DD/YYYY")}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>OIG Exclusion Type</div>
                      <div className="fw-bold">{OIG_ExclusionType}</div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>OIG Waiver</div>
                      <div className="fw-bold">{OIG_ExclusionWaiver}</div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>Status</div>
                      <div className="fw-bold">
                        {OIG_Verified ? "Verified" : "Not Verified"}
                      </div>
                    </Col>

                    {OIG_DateVerified ? (
                      <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                        <div>Date Verified</div>
                        <div className="fw-bold">
                          {moment(OIG_DateVerified).format("MM/DD/YYYY")}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                ) : (
                  <NoData loggedUser={user} />
                )}
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

ProviderOigExclusion.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  currentProviderOigExclusion:
    state.providerOigExclusion.currentProviderOigExclusion,
  loadingProviderOigExclusion:
    state.providerOigExclusion.loadingProviderOigExclusion,
});

export default connect(mapStateToProps, {
  setErrors,
  removeProviderOigExclusionErrors,
  saveProviderOigExclusion,
  getProviderOigExclusionById,
  resetProviderOigExclusionComponentStore,
})(ProviderOigExclusion);
