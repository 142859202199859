import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isAdmin } from "utils/helper";
import { MdEdit } from "react-icons/md";
import Spinner from "views/Spinner";

import {
  setErrors,
  removeProviderNpiErrors,
  saveProviderNpi,
  getProviderNpiById,
  resetProviderNpiComponentStore,
} from "actions/providerNpiActions";
import EditProviderNpiModal from "./EditProviderNpiModal";
import NoData from "./NoData";

const ProviderNpi = ({
  user,
  setErrors,
  errorList,
  removeProviderNpiErrors,
  saveProviderNpi,
  getProviderNpiById,
  currentProviderNpi,
  loadingProviderNpi,
  resetProviderNpiComponentStore,
}) => {
  const { provider_id } = useParams();
  const [modal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [isMedicalProvider, setMedicalProvider] = React.useState(false);
  React.useEffect(() => {
    if (!currentProviderNpi) {
      return;
    }

    if (currentProviderNpi.isMedicalProvider) {
      setMedicalProvider(true);
    } else {
      setMedicalProvider(false);
    }
  }, [currentProviderNpi]);

  const initialFormData = {
    NPI: "",
    EnumerationDate: "",
    Verified: false,
    DateVerified: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);

  const { NPI, EnumerationDate, Verified, DateVerified } = formData;

  const loadProviderNpiFormData = (currentProviderNpi) => {
    const { NPI, EnumerationDate, Verified, DateVerified } = currentProviderNpi;

    const data = {
      NPI,
      EnumerationDate: EnumerationDate
        ? moment(EnumerationDate).format("YYYY-MM-DD")
        : "",
      Verified,
      DateVerified: DateVerified
        ? moment(DateVerified).format("YYYY-MM-DD")
        : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!provider_id) return;
    resetProviderNpiComponentStore();
    getProviderNpiById(provider_id);
  }, [provider_id, getProviderNpiById]);

  React.useEffect(() => {
    if (!currentProviderNpi) {
      setFormData(initialFormData);
      return;
    }

    loadProviderNpiFormData(currentProviderNpi);
  }, [currentProviderNpi]);

  const onEditClickHandler = () => {
    setModal(true);
    setDisabled(!isDisabled);
  };

  return (
    <Col xs="12" sm="12" lg="12">
      {loadingProviderNpi ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {isMedicalProvider ||
          (currentProviderNpi && currentProviderNpi.isMedicalProvider) ? (
            <div>
              <EditProviderNpiModal
                provider_id={provider_id}
                modal={modal}
                setModal={setModal}
                isDisabled={isDisabled}
                setDisabled={setDisabled}
                formData={formData}
                setFormData={setFormData}
                errorList={errorList}
                setErrors={setErrors}
                currentProviderNpi={currentProviderNpi}
                loadProviderNpiFormData={loadProviderNpiFormData}
                getProviderNpiById={getProviderNpiById}
                saveProviderNpi={saveProviderNpi}
                removeProviderNpiErrors={removeProviderNpiErrors}
              />

              <Card className="mt-5">
                <Card.Body className="misc-info-card">
                  {" "}
                  <div className="card-heading mb-3">
                    {" "}
                    <h4 className="header-title">NPI Number</h4>
                    {isAdmin(user) ? (
                      <Button
                        variant="link"
                        size="sm"
                        className="float-end"
                        onClick={() => onEditClickHandler()}
                      >
                        <MdEdit title="Click to Edit" size={20} />
                      </Button>
                    ) : null}
                  </div>
                  <React.Fragment>
                    {NPI ? (
                      <Row>
                        {" "}
                        <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                          <div>NPI Number</div>
                          <div className="fw-bold">{NPI}</div>
                        </Col>
                        {EnumerationDate ? (
                          <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                            <div>Enumeration Date</div>
                            <div className="fw-bold">
                              {moment(EnumerationDate).format("MM/DD/YYYY")}
                            </div>
                          </Col>
                        ) : null}
                        <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                          <div>Status</div>
                          <div className="fw-bold">
                            {Verified ? "Verified" : "Not Verified"}
                          </div>
                        </Col>
                        {Verified ? (
                          <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                            <div>Date Verified</div>
                            <div className="fw-bold">
                              {moment(DateVerified).format("MM/DD/YYYY")}
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    ) : (
                      <NoData loggedUser={user} />
                    )}
                  </React.Fragment>
                </Card.Body>
              </Card>
            </div>
          ) : null}
        </React.Fragment>
      )}
    </Col>
  );
};

ProviderNpi.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  currentProviderNpi: state.providerNpi.currentProviderNpi,
  loadingProviderNpi: state.providerNpi.loadingProviderNpi,
});

export default connect(mapStateToProps, {
  setErrors,
  removeProviderNpiErrors,
  getProviderNpiById,
  saveProviderNpi,
  resetProviderNpiComponentStore,
})(ProviderNpi);
