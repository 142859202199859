import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import Spinner from "views/Spinner";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import { orgTypesGlobal } from "constants/index";

import {
  updateProvider,
  cancelSave,
  setErrors,
  removeProviderErrors,
  resetComponentStore,
  getProviderById,
  getStatesList,
  getOrganizationsList,
} from "actions/providerActions";

import AppBreadcrumb from "../Layout/AppBreadCrumb";

const EditProvider = ({
  updateProvider,
  setErrors,
  removeProviderErrors,
  loadingProvider,
  currentProvider,
  errorList,
  getProviderById,
  resetComponentStore,
  getStatesList,
  getOrganizationsList,
  organizationsList,
}) => {
  const navigate = useNavigate();
  const { provider_id } = useParams();

  const [displayName, setDisplayName] = React.useState("");
  const [organizations, setOrganizations] = React.useState([]);
  const [selectedOrg, setSelectedOrg] = React.useState();
  const [selectedOrgType, setSelectedOrgType] = React.useState();

  React.useEffect(() => {
    getOrganizationsList();
  }, [getOrganizationsList]);

  React.useEffect(() => {
    if (!organizationsList.length) return;

    setOrganizations(organizationsList);
  }, [organizationsList]);

  const initialFormData = {
    type: "",
    firstName: "",
    middleName: "",
    lastName: "",
    orgName: "",
    orgType: "",
    dateOfBirth: "",
    phone: "",
    email: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zipCode: "",
    medicalProvider: false,
    npiNumber: "",
    deaNumber: "",
    tin: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);

  const [npi, setNpi] = React.useState("");

  const [statesList, setStatesList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);

  const toggleEdit = () => setDisabled(!isDisabled);

  const {
    type,
    firstName,
    middleName,
    lastName,
    orgName,
    dateOfBirth,
    phone,
    email,
    address_line1,
    address_line2,
    city,
    state,
    zipCode,
    medicalProvider,
    deaNumber,
    tin,
  } = formData;

  const loadProviderFormData = (currentProvider) => {
    const {
      type,
      firstName,
      middleName,
      lastName,
      orgName,
      orgType,
      associated_org,
      dateOfBirth,
      phone,
      email,
      address_line1,
      address_line2,
      city,
      state,
      zipCode,
      medicalProvider,
      npiNumber,
      deaNumber,
      tin,
    } = currentProvider;

    const data = {
      type,
      firstName,
      middleName,
      lastName,
      orgName,
      orgType,
      associated_org,
      dateOfBirth,
      phone,
      email,
      address_line1,
      address_line2,
      city,
      state,
      zipCode,
      medicalProvider,
      npiNumber,
      deaNumber,
      tin,
    };

    setNpi(npiNumber);

    setFormData((formData) => ({ ...formData, ...data }));

    let displayName = `${firstName} ${
      middleName ? middleName : ""
    } ${lastName}`;
    if (type === "organization") {
      displayName = orgName;
    }
    setDisplayName(displayName);

    const selected = orgTypesGlobal.find((o) => o.value === orgType);
    setSelectedOrgType(selected);
  };

  React.useEffect(() => {
    if (!currentProvider || !organizationsList.length) return;

    const assoc_org = currentProvider.associated_org
      ? currentProvider.associated_org
      : "";
    let selected = organizationsList.find(
      (o) => o._id.toString() === assoc_org.toString()
    );

    if (selected) {
      setSelectedOrg({
        value: selected._id,
        label: selected.orgName,
      });
    }
  }, [currentProvider, organizationsList]);

  const onClickHandel = (e) => {
    e.preventDefault();
    // toggleEdit();
    navigate(`/admin/providers/${provider_id}/provider-dashboard`);
  };

  const handleStateSelect = (selectedRole) => {
    setSelectedState({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    setFormData({
      ...formData,
      state: selectedRole ? selectedRole.value : null,
    });
  };

  React.useEffect(() => {
    if (!currentProvider) return;

    loadProviderFormData(currentProvider);
  }, [currentProvider]);

  React.useEffect(() => {
    if (!provider_id) return;
    if (!state) return;

    const filteredState = statesList.find(
      (each) => each.abbreviation === state
    );

    if (filteredState) {
      setSelectedState({
        value: filteredState.abbreviation,
        label: `${filteredState.name} (${filteredState.abbreviation})`,
      });
    }
  }, [state, provider_id, statesList]);

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      case "medicalProvider":
        if (e.target.value === "false") {
          setNpi("");
        }
        setFormData({
          ...formData,
          [e.target.name]: e.target.value === "false" ? false : true,
          npiNumber: e.target.value === "false" ? "" : npi,
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleNPINumber = (e) => {
    const value = e.target.value;
    setNpi(value);
    setFormData({
      ...formData,
      npiNumber: value ? value : null,
    });
  };

  const handleOrgTypeSelect = (selectedOrgType) => {
    setSelectedOrgType(selectedOrgType);
  };

  const handleOrgSelect = (selectedOrg) => {
    setSelectedOrg(selectedOrg);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeProviderErrors();

    if (selectedOrgType) {
      formData["orgType"] = selectedOrgType["value"];
    }

    if (selectedOrg) {
      formData["associated_org"] = selectedOrg["value"];
    }

    let validationRules = [
      {
        param: "type",
        msg: "The Provider type is required",
      },
      {
        param: "phone",
        msg: "The Phone Number is required",
      },
      {
        param: "email",
        msg: "The Email is required",
      },
    ];

    if (formData.type === "individual") {
      validationRules.push({
        param: "firstName",
        msg: "The First Name is required",
      });
      validationRules.push({
        param: "lastName",
        msg: "The Last Name is required",
      });
    }

    if (formData.type === "organization") {
      validationRules.push({
        param: "orgName",
        msg: "The Organization Name is required",
      });

      validationRules.push({
        param: "orgType",
        msg: "The Organization Type is required",
      });
    }

    if (medicalProvider) {
      validationRules.push({
        param: "npiNumber",
        msg: "The NPI Number is required",
      });
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (formData[i] === null || formData[i] === undefined) continue;
      submitData[i] = formData[i];
    }

    // console.log("Before Update", submitData);
    // return;
    setSubmitting(true);
    updateProvider(submitData, navigate, provider_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useMemo(() => {
    if (!provider_id) return;

    resetComponentStore();

    getProviderById(provider_id);
  }, []);

  React.useMemo(async () => {
    const stateListData = await getStatesList();

    setStatesList(stateListData);
  }, [getStatesList]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle={
          !loadingProvider && firstName && lastName
            ? `Edit Provider "${displayName}"` //`Edit Provider "${firstName} ${lastName}"`
            : ""
        }
        crumbs={[
          { name: "Providers", path: "/admin/providers" },
          {
            name: displayName ? displayName : "Provider Dashboard",
            path: `/admin/providers/${provider_id}/provider-dashboard`,
          },
          {
            name: "Edit Provider",
          },
        ]}
      />

      {loadingProvider ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6" lg="12">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <h4 className="header-title mb-3">Provider Information</h4>

                  {/* <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button> */}
                </div>

                <Row>
                  <Col sm="3" md="1">
                    <Form.Group controlId="individual" className="form-group">
                      <Form.Check
                        type="radio"
                        label="Individual"
                        id="individual"
                        name="type"
                        value="individual"
                        checked={type !== "organization"}
                        onChange={(e) => false && onChange(e)}
                        disabled={isDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="3" md="1">
                    <Form.Group controlId="organization" className="form-group">
                      <Form.Check
                        type="radio"
                        label="Organization"
                        id="organization"
                        name="type"
                        value="organization"
                        checked={type === "organization"}
                        onChange={(e) => false && onChange(e)}
                        disabled={isDisabled}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {type === "individual" ? (
                  <Row>
                    <Col xs="12" sm="12" lg="3">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="firstName">
                          First Name <span>*</span>
                        </Form.Label>
                        <Form.Control
                          className={errorList.firstName ? "invalid" : ""}
                          type="text"
                          id="firstName"
                          name="firstName"
                          maxLength="40"
                          value={firstName}
                          onChange={(e) => onChange(e)}
                          disabled={isDisabled}
                        />
                        <Errors current_key="firstName" key="firstName" />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" lg="3">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="middleName">
                          Middle Name
                        </Form.Label>
                        <Form.Control
                          className={errorList.middleName ? "invalid" : ""}
                          type="text"
                          id="middleName"
                          name="middleName"
                          maxLength="40"
                          value={middleName}
                          onChange={(e) => onChange(e)}
                          disabled={isDisabled}
                        />
                        <Errors current_key="middleName" key="middleName" />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" lg="3">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">
                          Last Name <span>*</span>
                        </Form.Label>
                        <Form.Control
                          className={errorList.lastName ? "invalid" : ""}
                          type="text"
                          id="lastName"
                          name="lastName"
                          maxLength="40"
                          value={lastName}
                          onChange={(e) => onChange(e)}
                          disabled={isDisabled}
                        />
                        <Errors current_key="lastName" key="lastName" />
                      </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" lg="3">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="associatedOrg">
                          Associated Organization
                        </Form.Label>

                        <Select
                          id="associatedOrg"
                          name="associatedOrg"
                          options={organizations.map((org) => ({
                            label: org.orgName,
                            value: org._id,
                          }))}
                          onChange={handleOrgSelect}
                          invalid={errorList.associatedOrg ? true : false}
                          value={selectedOrg}
                          disabled={isDisabled}
                        />

                        <Errors
                          current_key="associatedOrg"
                          key="associatedOrg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}

                {type === "organization" ? (
                  <Row>
                    <Col xs="12" sm="12" lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="orgName">
                          Organization Name <span>*</span>
                        </Form.Label>
                        <Form.Control
                          className={errorList.orgName ? "invalid" : ""}
                          type="text"
                          id="orgName"
                          name="orgName"
                          maxLength="40"
                          value={orgName}
                          onChange={(e) => onChange(e)}
                          disabled={isDisabled}
                        />
                        <Errors current_key="orgName" key="orgName" />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  {type === "organization" ? (
                    <Col xs="12" sm="12" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="orgType">
                          Organization Type <span>*</span>
                        </Form.Label>

                        <Select
                          id="orgType"
                          name="orgType"
                          options={orgTypesGlobal}
                          onChange={handleOrgTypeSelect}
                          invalid={errorList.orgType ? true : false}
                          value={selectedOrgType}
                          disabled={isDisabled}
                        />

                        <Errors current_key="orgType" key="orgType" />
                      </Form.Group>
                    </Col>
                  ) : null}

                  {type === "individual" ? (
                    <Col xs="12" sm="12" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="dateOfBirth">
                          Date Of Birth
                        </Form.Label>
                        <Form.Control
                          className={errorList.dateOfBirth ? "invalid" : ""}
                          type="date"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          value={dateOfBirth}
                          onChange={(e) => onChange(e)}
                          disabled={isDisabled}
                        />
                        <Errors current_key="dateOfBirth" key="dateOfBirth" />
                      </Form.Group>
                    </Col>
                  ) : null}

                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="phone">
                        Phone <span>*</span>
                      </Form.Label>

                      <Form.Control
                        className={errorList.phone ? "invalid" : ""}
                        type="text"
                        id="phone"
                        name="phone"
                        maxLength="20"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={phone}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />

                      <Errors current_key="phone" key="phone" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">
                        Email <span>*</span>
                      </Form.Label>

                      <Form.Control
                        className={errorList.email ? "invalid" : ""}
                        type="email"
                        id="email"
                        name="email"
                        maxLength="80"
                        value={email}
                        onChange={(e) => onChange(e)}
                        disabled="true"
                      />

                      <Errors current_key="email" key="email" />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="address_line1">
                        Address Line 1
                      </Form.Label>
                      <Form.Control
                        className={errorList.address_line1 ? "invalid" : ""}
                        type="text"
                        id="address_line1"
                        name="address_line1"
                        maxLength="80"
                        value={address_line1}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                      <Errors current_key="address_line1" key="address_line1" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="address_line2">
                        Address Line 2
                      </Form.Label>
                      <Form.Control
                        className={errorList.address_line2 ? "invalid" : ""}
                        type="text"
                        id="address_line2"
                        name="address_line2"
                        maxLength="80"
                        value={address_line2}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                      <Errors current_key="address_line2" key="address_line2" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="city">City</Form.Label>
                      <Form.Control
                        className={errorList.city ? "invalid" : ""}
                        type="text"
                        id="city"
                        name="city"
                        maxLength="40"
                        value={city}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                      <Errors current_key="city" key="city" />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="state">State</Form.Label>

                      <Select
                        id="state"
                        name="state"
                        options={statesList.map((state) => ({
                          value: state.abbreviation,
                          label: `${state.name} (${state.abbreviation})`,
                        }))}
                        value={selectedState}
                        onChange={handleStateSelect}
                        invalid={errorList.state ? true : false}
                        isDisabled={isDisabled}
                      />

                      <Errors current_key="state" key="state" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="zipCode">Zip Code</Form.Label>
                      <Form.Control
                        className={errorList.zipCode ? "invalid" : ""}
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        maxLength="10"
                        value={zipCode}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                      <Errors current_key="zipCode" key="zipCode" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" lg="4">
                    <Row>
                      <Form.Label htmlFor="medicalProvider">
                        Medical Provider
                      </Form.Label>
                      <Col sm="3" md="2">
                        <Form.Group
                          controlId="medicalProvider-true"
                          className="form-group"
                        >
                          <Form.Check
                            className={
                              errorList.medicalProvider ? "invalid" : ""
                            }
                            type="radio"
                            label="Yes"
                            id="medicalProvider-true"
                            name="medicalProvider"
                            value="true"
                            checked={medicalProvider}
                            onChange={(e) => onChange(e)}
                            disabled={isDisabled}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="3" md="1">
                        <Form.Group
                          controlId="medicalProvider-false"
                          className="form-group"
                        >
                          <Form.Check
                            className={
                              errorList.medicalProvider ? "invalid" : ""
                            }
                            type="radio"
                            label="No"
                            id="medicalProvider-false"
                            name="medicalProvider"
                            value="false"
                            checked={!medicalProvider}
                            onChange={(e) => onChange(e)}
                            disabled={isDisabled}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="npiNumber">NPI Number</Form.Label>
                      <Form.Control
                        className={errorList.npiNumber ? "invalid" : ""}
                        type="text"
                        id="npiNumber"
                        name="npiNumber"
                        maxLength="10"
                        value={npi}
                        onChange={handleNPINumber}
                        disabled={isDisabled || !medicalProvider}
                      />
                      <Errors current_key="npiNumber" key="npiNumber" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="deaNumber">DEA Number</Form.Label>
                      <Form.Control
                        className={errorList.deaNumber ? "invalid" : ""}
                        type="text"
                        id="deaNumber"
                        name="deaNumber"
                        maxLength="10"
                        value={deaNumber}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                      <Errors current_key="deaNumber" key="deaNumber" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="tin">TIN</Form.Label>
                      <Form.Control
                        className={errorList.tin ? "invalid" : ""}
                        type="text"
                        id="tin"
                        name="tin"
                        maxLength="10"
                        value={tin}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                      <Errors current_key="tin" key="tin" />
                    </Form.Group>
                  </Col>
                </Row>

                <br></br>

                <div className="float-end">
                  <Button
                    className="m-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditProvider.propTypes = {
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingProvider: state.provider.loadingProvider,
  currentProvider: state.provider.currentProvider,
  organizationsList: state.provider.organizationsList,
});

export default connect(mapStateToProps, {
  updateProvider,
  cancelSave,
  setErrors,
  removeProviderErrors,
  resetComponentStore,
  getProviderById,
  resetComponentStore,
  getStatesList,
  getOrganizationsList,
})(EditProvider);
