import React from "react";
import { Button } from "react-bootstrap";
import { MdDownload } from "react-icons/md";

function DownloadPsvFile({ handlerDownloadPSV }) {
  const onClickDownloadPSV = async () => {
    const { PSV_data, fileName } = await handlerDownloadPSV();
    if (!PSV_data || !fileName) return;

    downloadPSVFile(PSV_data, fileName);
  };

  const downloadPSVFile = (PSV_data, fileName) => {
    // create file in browser
    const json = JSON.stringify(PSV_data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <Button variant="link" size="sm" onClick={onClickDownloadPSV}>
      <MdDownload title="Click to Edit" size={20} /> Download PSV File
    </Button>
  );
}

export default DownloadPsvFile;
